<template>
    <div class="weighing">
        <!-- 商品扫描提示 -->
        <a-modal  :title="edit ? '编辑商品' : '新增商品'" :visible="showModal" width="80vw" @cancel="handleCancel">
            <div  :class="[expend ? 'expend' : '' , 'content','addGoods']">
                <div class="title">商品基本信息</div>
                <a-form-model  ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 4}">
                    <a-form-model-item  label="销售方式"  class="xiao">
                        <div class="type">
                            <div v-for="(item,index) in type" :key="index" :class="[form.is_weighing==item.id ? 'active' : '' , 'normal',]"  @click="form.is_weighing=item.id">{{item.value}}</div>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item prop="goods_no" label="条形码" extra="扫描标准条形码将自动填充商品信息" class="right">
                        <a-input v-model="form.goods_no" placeholder="请填写标准条形码" :allowClear="true" @blur="inputBlur"  ref="inputbox"/>
                    </a-form-model-item>
                    <a-form-model-item ref="goods_name" prop="goods_name" label="商品名称" class="top">
                        <a-input v-model="form.goods_name" placeholder="请输入商品名称" @click.stop="is_blur=false"/>
                    </a-form-model-item>
                    <a-form-model-item ref="goods_first_chars" prop="goods_first_chars" label="速查码" class="right top">
                        <div style="width: 24vw;height: 4.9vh;line-height: 4.9vh;border: 1px solid #d9d9d9;padding:0 0.5vw;border-radius: 4px;font-size: 1.8vh;">{{form.goods_first_chars}}</div>
                    </a-form-model-item>
                    <a-form-model-item prop="goods_price" label="零售价" class="right"  v-if="!(permission.indexOf('/clerk/goods/changeRetailPrice') < 0 && edit)">
                        <div  @click.stop="is_blur=false" >
                            <a-input v-model="form.goods_price" placeholder="请输入价格" prefix="￥" suffix="/KG" v-if="form.is_weighing==2"/>
                            <a-input v-model="form.goods_price" placeholder="请输入价格" prefix="￥" v-if="form.is_weighing==1"/>
                        </div>
                    </a-form-model-item>
                    <!-- 进价（original_price） 供应商（supplier） PLU（goods_plu） -->
                    <a-form-model-item prop="original_price" label="进价" class="right">
                        <div  @click.stop="is_blur=false" >
                            <a-input v-model="form.original_price" placeholder="请输入进价" prefix="￥" suffix="/KG" v-if="form.is_weighing==2"/>
                            <a-input v-model="form.original_price" placeholder="请输入进价" prefix="￥" v-if="form.is_weighing==1"/>
                        </div>
                    </a-form-model-item>
                     <a-form-model-item prop="wholesale_price" label="批发价" class="right">
                        <div  @click.stop="is_blur=false" >
                            <a-input v-model="form.wholesale_price" placeholder="请输入批发价" prefix="￥" suffix="/KG" v-if="form.is_weighing==2"/>
                            <a-input v-model="form.wholesale_price" placeholder="请输入批发价" prefix="￥" v-if="form.is_weighing==1"/>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item prop="stock_total" label="库存数量" class="right">
                        <div  @click.stop="is_blur=false">
                            <a-input v-model="form.stock_total" placeholder="请输入库存数量"/>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item  label="是否超卖" class="right" >
                        <a-switch v-model="chao"  @change="chaoMai" un-checked-children="否" checked-children="是" />
                    </a-form-model-item>
                    
                    <a-form-model-item label="商品分类" prop="region" class="right">
                        <div @click.stop="changeSelect">
                            <a-select v-model="form.category_id" placeholder="请选择" style="width:24vw;height:50px" @click.stop="is_blur=false" dropdownClassName="dropdownstyle">
                                <a-select-option :value="item.category_id" v-for="(item,index)  in cateList" :key="index">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="商品单位" prop="region" class="right">
                        <div @click.stop="changeSelect">
                            <a-select v-model="form.goods_unit" placeholder="请选择" style="width:24vw" >
                                <a-select-option :value="item.goods_unit_name" v-for="(item,index) in unitsArr" :key="index">
                                    {{item.goods_unit_name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-form-model-item>
                    <div class="ge"></div>
                     <a-form-model-item label="会员折扣" prop="is_alone_grade" extra="开启后会员折扣，会员购买此商品可以享受会员等级折扣价"  class="right">
                        <a-radio-group v-model="zhe" @change="zheDis">
                            <a-radio value="2">
                            关闭
                            </a-radio>
                            <a-radio value="0">
                            默认等级折扣
                            </a-radio>
                            <a-radio value="1">
                            单独设置折扣
                            </a-radio>
                        </a-radio-group>
                        <div v-if="zhe==1">
                            <div v-for="(item,index) in grade" :key="index" class="grade">
                                <div class="type">{{item.name}}</div>
                                <div  @click.stop="changeSelect" style="height:4.9vh" >
                                    <a-input-number :min="0" :max="9.9" v-model="form.alone_grade_equity[item.grade_id]" />
                                </div>
                                <div class="unit type">折</div>
                            </div>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item  label="生产日期" class="right">
                        <a-date-picker
                            v-model="form.create_date"
                            placeholder="请选择"
                            format="YYYY-MM-DD"
                            @change="onChange"
                        />
                    </a-form-model-item>
                    <a-form-model-item  label="保质期" class="right">
                         <span @click.stop="changeSelect">
                            <a-input  class="bao"  v-model="bao" @click.stop="is_blur=false">
                                <a-select slot="addonAfter" v-model="baoUnits">
                                    <a-select-option value="天">
                                        天
                                    </a-select-option>
                                    <a-select-option value="月">
                                        月
                                    </a-select-option>
                                </a-select>
                            </a-input>
                         </span>
                    </a-form-model-item>
                    <!-- <a-form-model-item  label="供应商" class="right">
                        <a-select v-model="form.supplier" placeholder="请选择" style="width:24vw" >
                            <a-select-option :value="item.goods_unit_name" v-for="(item,index) in unitsArr" :key="index">
                                {{item.goods_unit_name}}
                            </a-select-option>
                            <a-select-option value="1" >
                               供应商
                            </a-select-option>
                        </a-select>
                    </a-form-model-item> -->
                    <a-form-model-item  label="PLU" class="right">
                        <a-input v-model="form.goods_plu" placeholder="请输入PLU"  @click.stop="is_blur=false"/>
                    </a-form-model-item>
                     <a-form-model-item label="商品状态" prop="status" class="right">
                        <a-radio-group v-model="form.status">
                            <a-radio value="10">
                            上架
                            </a-radio>
                            <a-radio value="20">
                            下架
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="使用会员余额结算" prop="is_user_balance" extra="开启后，会员购买此商品可以使用会员余额结算">
                        <a-radio-group v-model="form.is_user_balance">
                            <a-radio :value="1">开启</a-radio>
                            <a-radio :value="2">关闭</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="ge"></div>
            <div class="clickButton"><div @click="zhanButton"><span>{{!expend?'展开更多信息':'收起信息'}}</span><a-icon type="down" v-if="!expend"/><a-icon type="up" v-if="expend"/></div></div>
            <template slot="footer">
                <div  :class="[edit==false ? 'footerButton' : 'editFalse']" >
                    <div class="left">
                        <a-button class="clearBtn textColor" @click="handleCancel">关闭</a-button>
                        <a-button  class="OkBtn" @click="handleOk(false)">保存</a-button>
                    </div>
                    <div class="rgiht" v-if="edit==false">
                        <a-button  class="OkBtn add" @click="handleOk(true)">保存并继续添加</a-button>
                    </div>  
                </div>  
            </template>
        </a-modal>
    </div>
</template>
<script>
import { getGoodsByBarcode,addGoods,units } from "@/request/fast_food_settle";
import {categoryList,editGoods} from '@/request/business.js'
import {queryMembersgrade} from '@/request/mis.js'
import Pinyin from 'js-pinyin'
import { mapState } from "vuex";

export default {
    name:'weighing',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        },
        barcode:{
        },
        list:{

        },
        add:{

        },
        edit:{
            default:false
        },
        num:{}
        
    },
    data(){
        return{
            type:[{value:'常规商品',id:1},{value:'称重商品',id:2},],
            form:{
                goods_no:'',//条形码
                is_weighing:1,//是否称重：1不称重2称重
                goods_name:"",//商品名称
                goods_price:undefined,//零售价
                stock_total:undefined,//库存
                is_oversold:2,//是否允许超卖：1不允许2允许
                create_date:null,//生产日期
                shelf_life:'',//保质期
                goods_unit:undefined,//商品单位
                status:'10',//商品状态(10上架 20下架)
                category_id:undefined,//商品分类
                is_enable_grade:1,
                is_alone_grade:0,
                alone_grade_equity:{},
                is_user_balance:1,//是否允许使用余额支付：1允许2不允许
                
            },
            rules:{
                goods_name:[{ required: true, message: '请输入商品名称', trigger:'change'}],
                goods_price:[{ required: true, message: '请输入价格', trigger:'change'},]
            },
            expend:false,
            unitsArr:[],
            chao:true,
            cateList:[],
            baoUnits:"天",//保质期单位
            bao:"",//保质期时间
            zhe:"0",
            discount:"0",//设置折扣
            timeout:"",//延时器
            is_blur:true,
            grade:[],//等级数组
        }
    },
    computed: {
        ...mapState(["permission"]),
    },
    watch:{
        showModal(val){
            if(val){
                // console.log( Pinyin.getFullChars('全拼') );      
                // console.log( Pinyin.getCamelChars('首字母') ); 
                // console.log( Pinyin.getCamelChars('转小写首字母').toLocaleLowerCase() );
                // console.log( Pinyin.getCamelChars('12带数字34') ); 
                // console.log( Pinyin.getCamelChars('a带拼音b') ); 
                units().then(res => {
                    if(res.data.status === 200){
                        const data=res.data.data.list && res.data.data.list;
                        this.unitsArr=data;
                    }
                }) 
                this.tabRequest()
                this.getmis()
                this.inputBlur()
                this.form.goods_no=this.barcode;
                window.addEventListener("click", this.clickTotal);
                // console.log(JSON.stringify(this.list))
                if(this.list && this.add==false){
                    const list =this.list;
                    this.baoUnits=list.shelf_life.substr(list.shelf_life.length-1,1)//保质期单位
                    this.bao=list.shelf_life.length>1? parseInt(list.shelf_life):""//保质期时间
                    if(list.is_enable_grade==0){
                        this.zhe='2'
                    }else if(list.is_enable_grade==1 && list.is_alone_grade==0){
                        this.zhe='0'
                    }else if(list.is_enable_grade==1 && list.is_alone_grade==1){
                        this.zhe='1'
                    }
                    if(list.is_oversold==1){
                        this.chao=false;
                    }else if(list.is_oversold==2){
                        this.chao=true
                    }
                    console.log(list.is_enable_grade)
                    console.log(list.is_enable_grade)
                    console.log(list.is_oversold)
                    // this.zhe=list.is_alone_grade==0?'2':'1'
                    this.form= {
                    original_price:list.original_price,//进价
                    supplier:list.supplier,//供应商
                    goods_plu:list.goods_plu,//PLU
                    goods_no:list.goods_no,//条形码
                    is_weighing:list.is_weighing,//是否称重：1不称重2称重
                    goods_name:list.goods_name?list.goods_name:undefined,//商品名称
                    goods_price:list.goods_price_min?list.goods_price_min:undefined,//零售价
                    stock_total:list.stock_total,//库存
                    is_oversold:list.is_oversold,//是否允许超卖：1不允许2允许
                    create_date:list.create_date?list.create_date:undefined,//生产日期
                    shelf_life:"",//保质期
                    goods_unit:list.goods_unit?list.goods_unit:undefined,//商品单位
                    is_alone_grade:list.is_alone_grade,//会员折扣设置(0默认等级折扣 1单独设置折扣)
                    status:list.status.toString(),//商品状态(10上架 20下架)
                    category_id:list.category_id !=0?list.category_id:undefined,//商品分类
                    is_enable_grade:list.is_enable_grade,
                    alone_grade_equity:list.alone_grade_equity?list.alone_grade_equity:{},
                    is_user_balance:list.is_user_balance?list.is_user_balance:1,//是否允许使用余额支付：1允许2不允许  
                    }
                }
            }
        },
        'form.goods_name'(val){
            this.$set(this.form , 'goods_first_chars' , Pinyin.getCamelChars(val))
        },
        'form.goods_no'(val,vals){
           if(val&&val.length>0){
               const reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
               const han=reg.test(val)
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    if(this.edit==false && han==false){
                        this.getData(); 
                    }else if(this.edit==false && han==true){
                        this.$message.error("请输入有效格式条形码")
                        this.form.goods_no=""
                    }
                   
                },10)
               
                //  setTimeout(() => {
                //      console.log('4444')
                //     this.$refs.inputbox.blur();
                // },1000)
            }else{
                 this.form= {
                    goods_no:'',//条形码
                    is_weighing:1,//是否称重：1不称重2称重
                    goods_name:"",//商品名称
                    goods_price:undefined,//零售价
                    stock_total:undefined,//库存
                    is_oversold:2,//是否允许超卖：1不允许2允许
                    create_date:undefined,//生产日期
                    shelf_life:'',//保质期
                    goods_unit:undefined,//商品单位
                    status:'10',//商品状态(10上架 20下架)
                    category_id:undefined,//商品分类
                    is_enable_grade:1,
                    is_alone_grade:0,
                    alone_grade_equity:{},   
                    is_user_balance:1,//是否允许使用余额支付：1允许2不允许
                }
                this.baoUnits="天"//保质期单位
                this.bao=""//保质期时间
                this.zhe="0"
                this.chao=true;
                this.inputBlur()
                // setTimeout(() => {
                //     this.$refs.inputbox.focus();
                // },100)
               
                } 
        },
        is_blur(val){
            console.log(val)
            if(val){
                this.inputBlur()
            }
        }
    },
    methods:{
        handleCancel(){
            this.$emit('closeAddGoods')
            this.form= {
                goods_no:'',//条形码
                is_weighing:1,//是否称重：1不称重2称重
                goods_name:"",//商品名称
                goods_price:undefined,//零售价
                stock_total:undefined,//库存
                is_oversold:2,//是否允许超卖：1不允许2允许
                create_date:undefined,//生产日期
                shelf_life:'',//保质期
                goods_unit:undefined,//商品单位
                status:'10',//商品状态(10上架 20下架)
                category_id:undefined,//商品分类
                is_enable_grade:1,
                is_alone_grade:0, //会员折扣设置(0默认等级折扣 1单独设置折扣)
                alone_grade_equity:{}, 
                is_user_balance:1,//是否允许使用余额支付：1允许2不允许  
            }
            this.baoUnits="天"//保质期单位
            this.bao=""//保质期时间
            this.zhe="0"
            this.discount="0",//设置折扣
            this.expend=false
            this.chao=true;
        },
        handleOk(status){
            this.form.shelf_life=this.bao+this.baoUnits;
            for(let key in this.form.alone_grade_equity){
                this.form.alone_grade_equity['grade_id:' + key] = this.form.alone_grade_equity[key]
            }
            console.log(JSON.stringify(this.form))
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if(this.edit==false){
                        addGoods(this.form).then(res => {
                            if(res.data.status === 200){
                            this.$message.success('添加商品成功')
                            this.$emit('update')
                                this.form= {
                                    goods_no:'',//条形码
                                    is_weighing:1,//是否称重：1不称重2称重
                                    goods_name:"",//商品名称
                                    goods_price:undefined,//零售价
                                    stock_total:undefined,//库存
                                    is_oversold:2,//是否允许超卖：1不允许2允许
                                    create_date:undefined,//生产日期
                                    shelf_life:'',//保质期
                                    goods_unit:undefined,//商品单位
                                    status:'10',//商品状态(10上架 20下架)
                                    category_id:undefined,//商品分类
                                    is_enable_grade:1,
                                    is_alone_grade:0,  
                                    alone_grade_equity:{},
                                    is_user_balance:1,//是否允许使用余额支付：1允许2不允许
                                }
                                this.baoUnits="天"//保质期单位
                                this.bao=""//保质期时间
                                this.zhe="0"
                                this.discount="0"//设置折扣
                                this.expend=false
                                this.chao=true;
                            if(!status){
                                    this.$emit('closeAddGoods')
                                    this.$emit('addShops')
                            }
                            }
                        })
                    }else if(this.edit=true){
                        const goods_id=this.num && this.num
                        this.form.stock_num=this.form.stock_total?this.form.stock_total:"";
                        this.form.categoryIds=[this.form.category_id]
                        this.form.goods_id= goods_id
                        const arr={goodsId:goods_id,form:this.form}
                        editGoods(arr).then(res => {
                            if(res.data.status === 200){
                            this.$message.success('编辑商品成功')
                            this.$emit('update')
                                this.form= {
                                    goods_no:'',//条形码
                                    is_weighing:1,//是否称重：1不称重2称重
                                    goods_name:"",//商品名称
                                    goods_price:undefined,//零售价
                                    stock_total:undefined,//库存
                                    is_oversold:2,//是否允许超卖：1不允许2允许
                                    create_date:undefined,//生产日期
                                    shelf_life:'',//保质期
                                    goods_unit:undefined,//商品单位
                                    is_alone_grade:0,//会员折扣设置(0默认等级折扣 1单独设置折扣)
                                    status:'10',//商品状态(10上架 20下架)
                                    category_id:undefined,//商品分类
                                    is_enable_grade:1, 
                                    alone_grade_equity:{}, 
                                    is_user_balance:1,//是否允许使用余额支付：1允许2不允许
                                }
                                this.baoUnits="天"//保质期单位
                                this.bao=""//保质期时间
                                this.zhe="0"
                                this.discount="0"//设置折扣
                                this.expend=false
                                this.chao=true;
                            if(!status){
                                    this.$emit('closeAddGoods')
                            }
                            }
                        }) 
                    }
                } else {
                return false;
                }
            });
        },
        zhanButton(){
            this.expend=!this.expend;
        },
        getData(){
            getGoodsByBarcode({barcode:this.form.goods_no}).then(res => {
                if(res.data.status === 200){
                    if(res.data.data && res.data.data.isExists==1){
                        this.$message.error('该商品已存在',1.5)
                       this.form.goods_no="";
                    }else{
                        const data=res.data.data && res.data.data;
                        this.form.goods_name=data.name?data.name:this.list.goods_name;
                    }
                    
                    // this.form.goods_no=data.barcode;
                }
             })
        },
        tabRequest(){  //商品分类请求
            categoryList().then((res) => {
                if(res.data.status===200){
                    const list =res.data.data.list && res.data.data.list;
                    this.cateList = list;
                }           
            })
        },
        chaoMai(checked){
          this.form.is_oversold=checked?2:1
        },
        zheDis(e){
            const value=e.target.value;
            this.form.is_enable_grade=value==2?0:1;//开启关闭是否开启会员折扣(1开启 0关闭)
            this.form.is_alone_grade=value!=2?value:"";//会员折扣设置(0默认等级折扣 1单独设置折扣)
            this.form.alone_grade_equity={}
        },
        onChange(date, dateString){
            this.form.create_date=dateString
        }, 
        inputBlur(){
            // if(this.form.goods_no=""){
                setTimeout(() => {
                 if(this.is_blur&& this.form.goods_no.length==0){
                     this.$refs.inputbox.focus();
                 }  
                },100)
            // }
             
        },
        clickTotal(){
            this.is_blur=true;
        },
        changeSelect(){
            this.is_blur=false;
        },
        getmis(){
            queryMembersgrade().then((res) => {
                if(res.data.status===200){
                    const list =res.data.data.list.data && res.data.data.list.data;
                    this.grade = list;
                }           
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal{
    top: 55px;
}
/deep/.ant-modal-header{
    padding:20px 24px;
    .ant-modal-title{
        text-align: center;
        font-weight: bold;
        font-size: 2.3vh !important;
        color:#505050;
    }
}
/deep/.ant-modal-body{
    padding:0 0;
    .expend{
        height:auto !important;
    }
    .content{
        height:38vh;
        overflow: hidden;
        .title{
            font-size: 2.3vh;
            font-weight: bold;
            margin-left:1.4vw;
            margin-top: 1.5vh;
        }
        .ant-form{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .right{
              margin-right: 1.4vw;
              margin-left:0;
            }
            .top{
                margin-top:1.4vh !important;
            }
            .ant-form-item{
                margin-top: 2.6vh;
                margin-bottom: 0;
                width:33vw;
                margin-left:1.4vw;
                .ant-form-item-label{
                    width:8vw !important;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    text-align: right;
                    margin-right:1vw;
                    label{
                        color:#505050 !important;
                        text-align: right;   
                    }
                }
                .type{
                    display: flex;
                    font-size: 1.8vh;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    .normal{
                        font-size: 1.8vh;
                        border-radius: 4px;
                        width: 8.35vw;
                        height: 4.9vh;
                        line-height: 4.9vh;
                        border: 1px solid #cccccc;
                        text-align: center;
                        color:#d95e58;
                        margin-right:1.9vh;
                        cursor: pointer;
                    }
                    .active{
                        background: #d95e58;
                        border-color:#d95e58;
                        color:white;
                    }
                }
                .bao{
                   .ant-input{
                       width:18vw; 
                   } 
                   .ant-input-group-addon{
                       width:6vw;
                       .ant-select{
                            width:6vw !important;
                       }
                   }
                    .ant-select-selection__rendered,.ant-select-selection--single,.ant-select-selection-selected-value{
                        width:6vw;
                    }
                }
                .ant-radio-group{
                    display: flex;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    align-items: center;
                }
                .ant-input{
                    width:24vw;
                    height:4.9vh;
                }
                .ant-input-prefix{
                    color: #d2d2d2;
                }
                .ant-form-extra{
                    color:#999999;
                    font-size: 1.6vh;
                    width:24vw;
                }
               .ant-select-selection__rendered,.ant-select-selection--single,.ant-select-selection-selected-value{
                    width:24vw;
                    height:4.9vh;
                    line-height: 4.9vh;
                }
                .grade{
                    width:24vw;
                    height:4.9vh;
                    display: flex;
                    line-height: 4.9vh;
                    margin-bottom: 1vh;
                    .type{
                        line-height: 4.9vh;
                        background: #fafafa;
                        border: 1px solid #d9d9d9;
                        width:7.8vw;
                        height: 4.9vh;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        border-right: 0;
                    }
                    .unit{
                        width:4vw;
                        border-left: 0;
                        border-right: 1px solid #d9d9d9;
                    }
                   
                }
            }
            .xiao{
                .ant-col-4{
                    width:auto;
                }
            } 
        }
    }
}
/deep/.ant-modal-footer{
    display: flex;
    text-align: left;
    border-top:0 ;
    padding-bottom: 20px;
    .footerButton{
        width:100%;
        display: flex;
        justify-content: space-between;
    }
    .editFalse{
        width:100%;
        display: flex;
        justify-content:center;
    }
}
.OkBtn,.clearBtn{
    width:11.1vw;
    padding:0;
}
.OkBtn{
    margin-left: 2.6vw;
}
.add{
    width:15.9vw;
}
.ge{
    width:80vw;
    height:1px;
    background: #e3e3e3;
    margin-top: 2.6vh;
} 
.clickButton{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#d95e58;
    font-size: 2vh;
    height:6vh;
    cursor: pointer;
    span{
        margin-right:5px;
    }
}
/deep/.ant-switch-checked{
    background:#d95e58 ;
} 
/deep/.bao .ant-select-selection__rendered{
    margin-left: -11px;
    color:#d95e58;
}
/deep/.ant-radio-checked .ant-radio-inner{
    border-color: #d95e58;
}
/deep/.ant-radio-inner::after{
    background-color:#d95e58;
}
/deep/.span.ant-radio + *{
    padding-left: 0;
}
/deep/.ant-form-explain{
    width: 20vw;
}
// /deep/.ant-input-affix-wrapper .ant-input-suffix{
//     right: -18vw;
//     color:#d95e58;
// }
 /deep/.ant-input-number,/deep/.ant-input-number-input{
    height:4.9vh !important;
    border-radius: 0;
}
/deep/.ant-select-selection__placeholder,/deep/.ant-select-selection-selected-value {
    font-size: 1.8vh;
}
/deep/.dropdownstyle{
    font-size: 1.8vh !important;
}
</style>